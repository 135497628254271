const BaseUrl = {
    PASSPORT: 'https://passport.iqiv.cn',
    MALL_ADMIN: 'http://localhost:29182',
    ADMIN_ACCOUNT:'https://singin.iqiv.cn',
    STAFF_USER:'https://staff.iqiv.cn',
    SINGIN:'https://singin.iqiv.cn',
    EVALUATION:'https://evaluation.iqiv.cn',
    BANNER:'https://banner.iqiv.cn',
    HOSPITAL:'https://hospital.iqiv.cn',
    ORDER:'https://order.iqiv.cn',
    INFORMATIOON: 'https://personal.iqiv.cn',
    GOODS:'https://goods.iqiv.cn',
    announcement:'https://announcement.iqiv.cn',
    ATTACHMENT:'https://attachment.iqiv.cn'
};

export default BaseUrl;
