import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect:"/personal",
        component: HomeView,
        children: [
            {
                path: '/personal',
                name: 'personal',
                component: () => import( '../views/patient/personal.vue')
            }, {
                path: '/userOrder',
                name: 'userOrder',
                component: () => import( '../views/patient/userOrder.vue')
            },{
                path: '/announcement',
                name: 'announcement',
                component: () => import( '../views/patient/announcement.vue')
            },{
                path: '/signIn',
                name: 'signIn',
                component: () => import( '../views/patient/signIn.vue')
            },{
                path: '/comment',
                name: 'comment',
                component: () => import( '../views/patient/comment.vue')
            }
        ]
    },{
        path: '/reg',
        name: 'reg',
        component: () => import( '../views/RegUser.vue')

    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '../views/LoginView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
