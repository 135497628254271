<template>
  <div class="home">
    <!--    最外层-->
    <el-container>
      <!--      顶栏-->
      <el-header class="layout-header">
        <div class="login-user" style="float:right;">
          <span class="welcome" style="padding-right: 10px">欢迎回来，<b>{{loginInfo.username}}</b></span>
          <el-avatar :src="loginInfo.avatar"></el-avatar>
          <span class="welcome"><a href="javascript:void(0)" @click="logout()" style="color: white;margin-left: 10px;text-decoration: none">退出登录</a><br></span>
        </div>
        <h1>陪诊员</h1>
      </el-header>
      <!--      下半部分容器-->
      <el-container class="layout-body">
        <!--        左侧边栏-->
        <el-aside class="layout-aside">
          <el-menu
              router
              :default-active="$router.currentRoute.path"
              class="el-menu-vertical-demo"
              background-color="#3a4856"
              text-color="#fff"
              active-text-color="#fff">
            <el-menu-item index="/personal">陪诊员</el-menu-item>
            <el-menu-item index="/userOrder">客户订单</el-menu-item>
            <el-menu-item index="/announcement">公告</el-menu-item>
            <el-menu-item index="/signIn">签到</el-menu-item>
            <el-menu-item index="/comment">评论</el-menu-item>
          </el-menu>
        </el-aside>
        <!--        右侧主体-->
        <el-main class="layout-main">
          <div class="body">
            <!-- 页面的主体部分将由其它视图组件来显示 -->
            <router-view/>
          </div>
          <div class="copyright">
            <p>Copyright &copy; JSD2305 - IT学院 - Java教学研发部</p>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style>
body {margin: 0;}
.layout-header{background-color:#28292e;color: #fff }
.layout-header h1 { margin: 0; line-height: 60px; font-size: 20px;float: left; }
.layout-header .login-user { float: right; display: flex; margin-top: 12px; }
.layout-header .login-user .welcome { line-height: 36px; }
.layout-body { position: absolute; top: 60px; bottom: 0; left: 0; right: 0; }
.layout-aside { background: #3a4856; }
.layout-aside{border: #011f0e solid 2px}
.layout-main { background: #ddd; }
.layout-main .body { background: #fff; padding: 30px 30px 10px 30px; }
.layout-main .copyright p { color: #666; font-size: 11px; text-align: center; }
.layout-aside i { color: #fff !important; }
.layout-aside .el-menu { border: 0; text-align: left}
.layout-aside .el-menu .el-menu-item{ border: 0; }
.el-menu-item.is-active { background: #405872 !important; }
</style>

<script>

import TokenAxios from "@/http/TokenAxios";
import BaseUrl from "@/http/BaseUrl";

export default {
  data() {
    return {
      loginInfo:localStorage.loginInfo?JSON.parse(localStorage.loginInfo):null,
    }
  },
  mounted() {
    window.addEventListener('load', () => {
      var loginInfo = localStorage.getItem('loginInfo');
      if (!loginInfo) {
        alert('您尚未登录，请先登录！');
        window.location.href = "../login";
      }
    });
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout(){
      console.log(JSON.parse(localStorage.loginInfo))
      if (confirm("您确认退出登录吗?")){
        TokenAxios.get(BaseUrl.PASSPORT+'/passport/staffs/logout').then((response) => {
          console.log(JSON.parse(localStorage.loginInfo))
          let jsonResult = response.data;
          debugger
          if(jsonResult.state===20000){
            debugger
            localStorage.clear();
            this.$router.push('/login');
          }
        })
      }

    }
  }
}
</script>

